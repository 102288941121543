
import { defineComponent, ref, computed, reactive, onMounted } from 'vue'
import { FormInstance } from 'element-plus';
import { store } from "@/store";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Validations from '@/core/services/etc/Validations';
import { etc, rule, notification, common, informationModal } from '@/store/stateless';
import { corporateTop, corporateMiddle } from '@/core/utils'
import router from '@/router/clean';
import Customers from '@/core/services/Callable/Customers';
import Etcs from '@/core/services/Callable/Etcs';
import { ElLoading } from 'element-plus'

export default defineComponent({
    setup() {
        const corporateForm = ref<FormInstance>()

        const isSubmitted = ref(false)
        const top = ref()
        const middle = ref()
        const last = ref()
        const empRef = ref()

        const loading = ref(false)
        const health = computed(() => {
            return store.state.customer.health;
        })

        const categories = computed(() => {
            return store.state.etc.employeeCategories
        })

        const healthDef = ref({
            id: null,
            name: null,
            email: null,
            contact_person: null,
            grooup_size: null,
            phone_number: null,
            phone_country_code: "+971",
            other_contact_info:null,
            insured_city: null,
            insured_type_id: 0,
            group_size: 0,
            insurance_type: 0,
            is_salary: null,
            expected_insurance_start_date: null,
            customer_source: null,
            is_health: 1,
            utm_source: null,
            utm_medium: null,
            utm_campaign: null,
            utm_content: null,
            dob: null,
            nationality_id: null,
            gender: null,
            is_married: false,
            company_name: null,
            is_self: null,
            agent: null,
            health_lead_map_id: 0,
            members: [],
            categories: [],
            deleted_categories:[],
        })


        const rules = reactive({
                name: [{ validator: Validations.names, trigger: ['blur', 'change','input'] }],
                email: [{ validator: Validations.email, trigger: ['blur', 'change','input'] }],
                phone_number: [{ validator: Validations.phone_number, trigger: ['blur', 'change','input'] }],
                contact_person: [{ validator: Validations.names, trigger: ['blur', 'change','input'] }],
                // dob: [{ validator: Validations.dob, trigger: ['blur'] }],
                // gender: [{ validator: Validations.gender, trigger: ['blur'] }],
                // is_salary: [{ validator: Validations.is_salary, trigger: ['blur', 'change','input'] }],
                // is_self: [{ validator: Validations.is_self, trigger: ['blur', 'change','input'] }],
                // age_group: [{ validator: Validations.age_group, trigger: ['blur', 'change','input'] }],
                group_size: [{ validator: Validations.group_size, trigger: ['blur', 'change','input'] }],
                insured_city: [{ validator: Validations.insured_city, trigger: ['blur', 'change','input'] }],
                // insurance_type: [{ validator: Validations.insurance_type, trigger: ['blur'] }],
                // company_name: [{ validator: Validations.company_name, trigger: ['blur', 'change','input'] }],
                expected_insurance_start_date: [{ validator: Validations.expected_insurance_start_date, trigger: ['blur', 'change','input'] }],
                // nationality_id: [{ validator: Validations.nationality, trigger: ['blur', 'change','input'] }],
                // agent: [{ validator: Validations.agent, trigger: ['blur', 'change','input'] }]
            })


        const reset = () => {
            Customers.setHealth(healthDef.value)
            corporateForm.value?.clearValidate()
            rule.customerDetails = 'phone_number'
            setTimeout(() => {
                top.value.focus()
            }, 100);
        }

        onMounted(async () => {
            const elLoading = ElLoading.service({
                text: 'Loading',
                background: 'rgba(0, 0, 0, 0.1)',
            })
            reset()
            setCurrentPageBreadcrumbs("Add Group Details", [{ label: "Leads & Tasks", value: "CustomerLists" }]);
            await CustomerDetails()

            health.value.expected_insurance_start_date = common.getToday()

            health.value.insured_type_id = 4;
            health.value.insured_type_id_prev = 4;
            health.value.insurance_type = 2;
            health.value.expected_insurance_start_date = common.getToday()
            if (!store.getters.showByRole([1])) health.value.agent = store.getters.myId

            await Etcs.getEmployeeCategories();
            await Etcs.getInsurancePolicies({isDropdown: true, isGroup:true });
            await addNewCategory()

            elLoading.close()

        })

        const cd = computed(() => {
            return store.getters.getCustomer
        })

        const CustomerDetails = async(is_loaded = false) => {
            const params = {customer_id: parseInt(router.currentRoute.value.params.customer_id.toString())}
            if(is_loaded) {
                params['is_loaded'] = true
            }

            await Customers.details(params)
            // await Customers.getCustomerById(params)
            const { customer_id, name, phone_country_code, phone_number, email, dob,gender,nationality_id,is_salary } = cd.value
            health.value.id = customer_id
            health.value.name = name
            health.value.phone_country_code = phone_country_code
            health.value.phone_number = phone_number
            health.value.email = email
            health.value.dob = dob
            health.value.gender = gender
            health.value.nationality_id = nationality_id
            health.value.is_salary = is_salary
        }

        const addNewCategory = () => {
            if(!corporateForm.value) return

            const name = health.value.categories.map((x, i) => {
                return [`categories.${i}.name`, `categories.${i}.visa_emirates`, `categories.${i}.insurance_plan_id`]
            }).flat()
            
            if(health.value?.categories.length > 0)  
                corporateForm.value.validateField(name)
                .then((valid) => {
                    if(valid) {
                        health.value?.categories?.push({
                            id: null,
                            name: null,
                            insurance_provider_id: null,
                            insurance_providers: store.state.etc.providers,
                            insurance_plan_id: null,
                            insurance_policies: [],
                            group_count: 1,
                            members_above_60: 0,
                            visa_emirates: null,
                            
                            visa_emirates_text: null,
                            category_name: null,
                            insurance_plan: null,
                            amount:0
                        })
                    }
                })
                .catch((error) => {
                    rule.customerDetails = Object.keys(error)[0]
                    setTimeout(() => {
                        empRef.value.focus()
                    }, 100);
                })

            
            if(health.value.categories.length == 0) {
                health.value?.categories?.push({
                    id: null,
                    name: null,
                    insurance_provider_id: null,
                    insurance_providers: store.state.etc.providers,
                    insurance_plan_id: null,
                    insurance_policies: [],
                    group_count: 1,
                    members_above_60: 0,
                    visa_emirates: null,
                    
                    visa_emirates_text: null,
                    category_name: null,
                    insurance_plan: null,
                    amount:0
                })
            }          
            
        }

        const submitHealth = async (formEl: FormInstance | undefined) => {
            isSubmitted.value = true
            // console.log(health.value);

            if (!formEl) return
            formEl.validate(async (valid, fields: any) => {
                
                    if (valid) {
                        if(checkDuplicateCategory()) {
                            loading.value = true
                            const result = await Customers.updateLead(health.value);
                            if (result.status == 200) {
                                notification.success(
                                    'Customer',
                                    'Customer has been updated successfully'
                                );
                                // reset();
                                formEl.resetFields();
                                goLists(result.data.data.customer.id);
                            } else {
                                const { email_taken, phone_taken } = result.response.data.data
                                Validations.email_taken = email_taken
                                Validations.phone_taken = phone_taken
                                formEl.validate(async (valid) => {
                                })
                            }
                        } else {
                            // open the popup
                            loading.value = false
                            informationModal.value.open = true
                            informationModal.value.title = 'Duplicate Group Details'
                            informationModal.value.message = 'Category and Visa Issued Emirate is duplicated in the group details section. Kindly update the group details information to proceed.'
                        }
                    } else {
                        rule.customerDetails =  Object.keys(fields)[0]
                        console.log(rule.customerDetails)
                        if(corporateTop.includes(rule.customerDetails)) top.value.focus(); 
                        // else if(corporateMiddle.includes(rule.customerDetails)) middle.value.focus(); 
                        else empRef.value.focus()
                    }
                

                loading.value = false
                isSubmitted.value = false

            })
        }

        const checkDuplicateCategory = () => {
            const lookup = health.value.categories
            let isValid = true
            const oldValue = <any>[]

            for(const i of lookup) {
                if(oldValue.length > 0) {
                    let checker = oldValue.filter(x => x.name == i.name && x.visa_emirates == i.visa_emirates)
                    if(checker.length > 0) isValid = false
                }

                oldValue.push(i)
            }

            return isValid
        }

        const goLists = async (customer_id: number) => {
            await router.replace({ name: 'CustomerDetails', params: { customer_id } })
        }

        const checkPhoneExist = async() => {
            if (health.value.phone_number) {
                const data = await Etcs.checkExistNumber({
                    phone_country_code: health.value.phone_country_code,
                    phone_number: health.value.phone_number,
                    phoneNo:health.value.phone_number,
                    country: health.value.phone_country_code,
                });

                if(data && (data.is_health == 0 || !data.any_health_lead)){
                    health.value.name = data.name;
                    health.value.email = data.email;
                }
                // console.log(data);
            }
        }

        return {
            health,
            rules,
            top,
            middle,
            corporateForm,
            addNewCategory,
            submitHealth,
            goLists,
            loading,
            checkPhoneExist,
            empRef,
            categories,
            store
        }

    },
})
